<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="12" align="center" t-data="profile-image">
        <v-img
          :src="profile"
          aspect-ratio="1"
          class="profile-image rounded-circle mb-2"
          width="170"
          height="170"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center">
        <h4 class="mt-0 card-title" t-data="info-title">
          {{ name }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-img
          t-data="department-icon"
          slot="prependIcon"
          aspect-ratio="1"
          max-height="24"
          max-width="24"
          class="grey rounded-circle mb-4"
          src="/images/icons/department-icon.png"
        ></v-img>
      </v-col>
      <v-col cols="14">
        <p class="mb-1 body-regular department-title secondary-text--text" t-data="department-title">
          Department
        </p>

        <ul>
          <li>
            <h6 t-data="department-name">
              {{ departmentTitle }}
            </h6>
          </li>
        </ul>
      </v-col>
    </v-row>

    <div
      class="content-detail mt-2"
      t-data="description"
      v-html="description"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PreviewContent',
  props: {
    profile: {type: String},
    name: {type: String},
    description: {type: String},
    departmentTitle: {type: String},
  },
}
</script>

<style lang="scss">
.profile-image {
  border: 1px solid var(--border-color);
}
</style>
