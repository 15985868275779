<template>
  <v-layout fluid v-resize="onResize">
    <v-data-table
      :loading="isLoading"
      :headers="
        hasPermsByOrgs(orgList.selected.map(x => x.codename))
          ? table.headers
          : table.viewOnlyHeaders
      "
      :items="sortableList"
      :sort-desc="true"
      :must-sort="false"
      class="elevation-1 custom-table"
      fixed-header
      style="width: 100%"
      :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
      hide-default-footer
    >
      <template v-if="sortableTotal <= 0" v-slot:no-data>
        No data available
      </template>
      <template v-if="sortableTotal > 0" v-slot:body>
        <draggable
          v-model="draggableList"
          tag="tbody"
          handle=".handle"
          @start="dragStart"
          @end="dragEnd"
          :disabled="!hasPermsByOrgs(orgList.selected.map(x => x.codename))"
        >
          <tr v-for="(item, index) in draggableList" :key="item.id">
            <td v-if="hasPermEditByOrgs(item.orgs)">
              <v-img
                v-if="hasPermEditByOrgs(item.orgs)"
                class="handle"
                aspect-ratio="1"
                max-height="24"
                max-width="24"
                src="/images/grip-line-icon.png"
              ></v-img>
            </td>
            <td>
              {{ index + 1 }}
            </td>
            <td class="py-4">
              <v-img
                t-data="department-img"
                aspect-ratio="1"
                max-width="58"
                max-height="58"
                height="58"
                :src="item.profile"
              ></v-img>
            </td>
            <td>
              <tooltip-title :contents="item.contents">
                <template v-slot:content>
                  <v-list-item-content
                    class="d-inline-block text-truncate"
                    style="width: 450px"
                  >
                    <v-list-item-title>
                      {{ getContentByLang(item.contents, 'th') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getContentByLang(item.contents, 'en') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </tooltip-title>
            </td>
            <td>
              <v-switch
                t-data="status-switch"
                color="primary200Color"
                v-model="item.status"
                :false-value="inActiveStatus"
                :true-value="activeStatus"
                :disabled="!hasPermEditByOrgs(item.orgs)"
                @change="event => handleSwitchStatusChange(event, item)"
                class="mb-0 pb-0 custom-switch"
              ></v-switch>
            </td>
            <td v-if="hasPermEditByOrgs(item.orgs)">
              <v-icon
                t-data="sort-action"
                class="px-2 action-icon"
                @click="actionEdit(item)"
                >mdi-format-list-numbered</v-icon
              >
            </td>
          </tr>
        </draggable>
      </template>
      <template v-slot:footer
        ><div class="v-data-footer" style="height: 58px"></div>
      </template>
    </v-data-table>
  </v-layout>
</template>
<script>
import TooltipTitle from '@/components/TooltipTitle'
import {mapActions, mapState} from 'vuex'
import {doctorStatusEnum} from '@/constants/doctor'
import {convertDate, hasPermissionsByOrgs} from '@/helper/helper'

import draggable from 'vuedraggable'

export default {
  name: 'SortableTable',
  components: {
    TooltipTitle,
    draggable,
  },
  props: {},
  data() {
    return {
      heightOptions: {
        header: 372,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      activeStatus: doctorStatusEnum.ACTIVE,
      inActiveStatus: doctorStatusEnum.INACTIVE,
      table: {
        dragging: false,
        headers: [
          {
            text: '',
            align: 'left',
            sortable: false,
            value: 'order',
            width: 60
          },
          {
            text: 'No.',
            align: 'left',
            sortable: false,
            value: 'id',
            width: 34
          },
          {
            text: 'Image',
            align: 'left',
            sortable: false,
            value: 'profile',
            width: 60
          },
          {
            text: 'Title',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 450
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 65
          },
          {
            text: 'Actions',
            align: 'left',
            sortable: false,
            value: 'actions',
            width: 80
          }
        ],
        viewOnlyHeaders: [
          {
            text: 'No.',
            align: 'left',
            sortable: false,
            value: 'id',
            width: 34
          },
          {
            text: 'Image',
            align: 'left',
            sortable: false,
            value: 'profile',
            width: 60
          },
          {
            text: 'Title',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 450
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 65
          },
        ]
      }
    }
  },
  computed: {
    draggableList: {
      get() {
        return this.sortableList
      },
      set(val) {
        this.reorderList(val)
      }
    },
    ...mapState('doctor', [
      'isLoading',
      'orgList',
      'sortableList',
      'sortableTotal'
    ])
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    dragStart() {
      this.table.dragging = true
    },
    dragEnd() {
      this.table.dragging = false
    },
    hasPermEditByOrgs(orgs) {
      return hasPermissionsByOrgs('CanEditDoctor', orgs)
    },
    hasPermsByOrgs: codenames =>
      hasPermissionsByOrgs('CanEditDoctor', codenames),
    dateFmt: date => convertDate(date),
    async handleSwitchStatusChange(event, row) {
      this.setSelectedId([row])
      const isSuccess = await this.update(event)
      if (isSuccess) {
        await this.fetchSortableList()
        this.$emit('updateSuccess')
      } else {
        this.$emit('updateFail')
      }
    },
    getContentByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].name
      }
      return ''
    },
    getThumbnailByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].thumbnail
      }
      return ''
    },
    async actionEdit(item) {
      await this.setEditOrder(item)
    },
    ...mapActions('doctor', [
      'fetchSortableList',
      'reorderList',
      'setEditOrder',
      'setSelectedId',
      'update',
    ])
  }
}
</script>
