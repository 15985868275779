<template>
  <quick-preview :is-preview="show" @input="closePreview">
    <template v-slot:content-en>
      <preview-content
        :profile="profile"
        :name="enContentObject.name"
        :description="enContentObject.description"
        :departmentTitle="enDepartmentContentObject.title"
      />
    </template>
    <template v-slot:content-th>
      <preview-content
        :profile="profile"
        :name="thContentObject.name"
        :description="thContentObject.description"
        :departmentTitle="thDepartmentContentObject.title"
      />
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/QuickPreview'
import PreviewContent from '@/components/doctor/PreviewContent'

export default {
  name: 'Preview',
  components: {
    QuickPreview,
    PreviewContent
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    doctorObject: {type: Object}
  },
  data() {
    return {
      activeTab: 'th'
    }
  },
  computed: {
    profile() {
      return this.doctorObject.profile
    },
    enContentObject() {
      return this.doctorObject.contents.filter(obj => {
        return obj.code == 'en'
      })[0]
    },
    thContentObject() {
      return this.doctorObject.contents.filter(obj => {
        return obj.code == 'th'
      })[0]
    },
    enDepartmentContentObject() {
      return this.doctorObject.department.filter(obj => {
        return obj.code == 'en'
      })[0]
    },
    thDepartmentContentObject() {
      return this.doctorObject.department.filter(obj => {
        return obj.code == 'th'
      })[0]
    }
  },
  methods: {
    close() {
      this.closePreview()
    }
  }
}
</script>

